import { ContextSidebar } from '@/components/sidebars/context/ContextSidebar';
import { ContextSidebarMenu } from '@/components/sidebars/context/ContextSidebarMenu';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { IMenu } from '@/typings';

export default createComponent({
  name: 'RelatoriosSidebar',
  setup(props, ctx) {
    const menus: IMenu[] = [
      {
        text: 'Relatórios',
        header: true,
      },
      {
        text: 'Atendimentos',
        subheader: true,
      },
      {
        text: 'Atendimentos realizados',
        icon: MyIcons.relatorioAtendimentosRealizados,
        to: Routes.app.relatorio.atendimento.index,
      },
      {
        text: 'Pacientes para retorno',
        icon: MyIcons.relatorioPacientesRetorno,
        to: Routes.app.relatorio.atendimento.retorno,
      },
      {
        text: 'Pacientes por período',
        icon: MyIcons.relatorioPacientesPeriodo,
        to: Routes.app.relatorio.atendimento.periodo,
      },
      {
        text: 'Pacientes por CID',
        icon: MyIcons.cid10,
        to: Routes.app.relatorio.atendimento.cid,
      },
      {
        text: 'Paciente por indicação',
        icon: MyIcons.relatorioPacientesIndicacao,
        to: Routes.app.relatorio.atendimento.indicacao,
      },
      {
        text: 'Faltas por paciente',
        icon: MyIcons.relatorioFaltasPaciente,
        to: Routes.app.relatorio.atendimento.faltas,
      },
      {
        text: 'Finanças',
        subheader: true,
      },
      {
        text: 'Análise de despesas',
        icon: MyIcons.analiseDespesas,
        to: Routes.app.relatorio.financas.despesas,
      },
      {
        text: 'Análise de receitas',
        icon: MyIcons.analiseReceitas,
        to: Routes.app.relatorio.financas.receitas,
      },
      {
        text: 'Fluxo de caixa',
        icon: MyIcons.fluxoCaixa,
        to: Routes.app.relatorio.financas.fluxoCaixa,
      },
      {
        text: 'Relacionamentos',
        subheader: true,
      },
      {
        text: 'Aniversariantes',
        icon: MyIcons.aniversariantes,
        to: Routes.app.relatorio.aniversariantes,
      },
    ];

    return () => (
      <ContextSidebar>
        <ContextSidebarMenu menus={menus} />
      </ContextSidebar>
    );
  },
});
