import { DataTable } from '@/components/datatable/DataTable';
import { MyPage } from '@/components/page/MyPage';
import { PageSection } from '@/components/page/PageSection';
import { createComponent } from '@/lib/vue';
import { IDataTableHeader } from '@/typings';

export default createComponent({
  name: 'RelatorioAtendimentoPage',
  setup(props, ctx) {
    const items = [];

    const headers: IDataTableHeader[] = [
      {
        // pode ser agrupado por: Convênio, Paciente, Procedimento, Profissional de saúde
        text: 'Agrupamento',
        value: 'agrupamento',
      },
      {
        text: 'Atendimentos',
        value: 'atendimentos',
      },
    ];

    return () => (
      <MyPage title="Atendimentos realizados">
        <PageSection>
          <DataTable headers={headers} items={items} />
        </PageSection>
      </MyPage>
    );
  },
});
