import { DataTable } from '@/components/datatable/DataTable';
import { MyPage } from '@/components/page/MyPage';
import { PageSection } from '@/components/page/PageSection';
import { createComponent } from '@/lib/vue';
import { IDataTableHeader } from '@/typings';

export default createComponent({
  name: 'RelatorioAtendimentoRetornoPage',
  setup(props, ctx) {
    const items = [];

    const headers: IDataTableHeader[] = [
      {
        text: 'Paciente',
        value: 'paciente',
      },
      {
        text: 'Convênio',
        value: 'convenio',
      },
      {
        text: 'Procedimento',
        value: 'procedimento',
      },
      {
        text: 'Última consulta',
        value: 'ultimaConsulta',
      },
    ];

    return () => (
      <MyPage title="Pacientes para retorno">
        <PageSection>
          <DataTable
            headers={headers}
            items={items}
            // loading={loading}
          />
        </PageSection>
      </MyPage>
    );
  },
});
