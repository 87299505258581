import { DataTable } from '@/components/datatable/DataTable';
import { MyPage } from '@/components/page/MyPage';
import { PageSection } from '@/components/page/PageSection';
import { createComponent } from '@/lib/vue';
import { IDataTableHeader } from '@/typings';

export default createComponent({
  name: 'RelatorioAtendimentoCidPage',
  setup(props, ctx) {
    const items = [];

    const headers: IDataTableHeader[] = [
      {
        text: 'Paciente',
        value: 'paciente',
      },
      {
        text: 'Telefone',
        value: 'telefone',
      },
      {
        text: 'Última consulta',
        value: 'ultimaConsulta',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'CID',
        value: 'cid',
      },
    ];

    return () => (
      <MyPage title="Pacientes por CID">
        <PageSection>
          <DataTable
            headers={headers}
            items={items}
            // loading={loading}
          />
        </PageSection>
      </MyPage>
    );
  },
});
