import { DataTable } from '@/components/datatable/DataTable';
import { MyPage } from '@/components/page/MyPage';
import { PageSection } from '@/components/page/PageSection';
import { createComponent } from '@/lib/vue';
import { IDataTableHeader } from '@/typings';

export default createComponent({
  name: 'RelatorioAniversariantesPage',
  setup(props, ctx) {
    const items = [];

    const headers: IDataTableHeader[] = [
      {
        text: 'Paciente',
        value: 'paciente',
      },
      {
        text: 'Data de nascimento',
        value: 'dataNascimento',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Telefone',
        value: 'telefone',
      },
    ];

    return () => (
      <MyPage title="Aniversariantes">
        <PageSection>
          <DataTable
            headers={headers}
            items={items}
            // loading={loading}
          />
        </PageSection>
      </MyPage>
    );
  },
});
