import { DataTable } from '@/components/datatable/DataTable';
import { MyPage } from '@/components/page/MyPage';
import { PageSection } from '@/components/page/PageSection';
import { createComponent } from '@/lib/vue';
import { IDataTableHeader } from '@/typings';

export default createComponent({
  name: 'RelatorioAtendimentoIndicacaoPage',
  setup(props, ctx) {
    const items = [];

    const headers: IDataTableHeader[] = [
      {
        text: 'Data de cadastro',
        value: 'createdAt',
      },
      {
        text: 'Nome',
        value: 'nome',
      },
      {
        text: 'Data de Nascimento',
        value: 'dataNascimento',
      },
      {
        text: 'Convênios',
        value: 'convenios',
      },
      {
        text: 'Tipo de indicação',
        value: 'tipoIndicacao',
      },
      {
        text: 'Detalhes',
        value: 'detalhes',
      },
    ];

    return () => (
      <MyPage title="Pacientes por indicação">
        <PageSection>
          <DataTable
            headers={headers}
            items={items}
            // loading={loading}
          />
        </PageSection>
      </MyPage>
    );
  },
});
